<template>
    <div>
      <nav class="navbar navbar-expand-lg navbar-light fixed-top">
        <div class="container"> <a class="navbar-brand" href="/dashboard">
            <img class="img-fluid d-block" src="img/logo.png" style="width: 60%;">
          </a> <button class="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse" data-target="#navbar5">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbar5">
            
            <ul class="navbar-nav ml-auto text-center" v-if="isLoggedIn">
              <router-link  v-for="link in authlinks" :key="link.text" :to="link.route" class="nav-item"> <a class="nav-link">{{link.text}}</a></router-link>
              <li role="presentation" class="dropdown">
                <a class="dropdown-toggle nav-link" data-toggle="dropdown"  role="button" aria-haspopup="true" aria-expanded="false" style="font-size:14px;">
                  Cadastro <i class="fa fa-user fa-fw"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item"><router-link to="/dashboard/cadastro" class="nav-item"> <a class="btn btn-link mx-auto" >Meu Cadastro</a></router-link></li>
                  <li class="nav-item" @click="this.ActionDoLogout"><a class="btn btn-link mx-auto" style="margin-left:15px;cursor:pointer;">Sair</a></li>
                </ul>
              </li>
           </ul>
          </div>
          
        </div>
      </nav>

  <div id="inside">
   <router-view></router-view>
  </div>
  

   <div class="footer1 py-5" style="">
      <div class="container">
        <div class="row" width="100%">
            <div v-bind:class="[ this.largura < 800 ? 'col-1' : 'col-2' ]" >
          </div>
          <div v-bind:class="[ this.largura < 800 ? 'p-3 col-lg-6 col-4 col-md-4' : 'p-3 col-lg-6 col-8 col-md-4' ]" >
            <h5><b>Tricard</b></h5>
            <ul class="list-unstyled">
              <li><a href="/" style="color: #1641cc">Home</a></li>
              <router-link
                v-for="link in links"
                :key="link.text"
                :to="{
                  name: link.route,
                  query: {
                    title: link.text,
                  },
                }"
                ><li>
                  <a style="color: #1641cc">{{ link.text }}</a>
                </li></router-link
              > 
            </ul>
          </div>
            <div v-bind:class="[ this.largura < 800 ? 'p-3 col-lg-6 col-7 col-md-4': 'p-3 col-lg-2 col-md-2' ]" style="color: #1641cc">
            <p class="mb-0">Fale conosco:</p>
            <a class="btn btn-link" href="tel:+55143235-0900"
              ><i class="fa fa-phone fa-fw mx-1 fa-lg"></i>(xx) xxxx-xxxx</a
            ><a
              class="btn btn-link"
              href="https://api.whatsapp.com/send?l=pt&amp;phone=551132303765"
              target="_blank"
              ><i class="fa fa-whatsapp fa-fw mx-1 fa-lg"></i>(xx) xxxx -xxxx</a
            ><a class="btn btn-link" href="mailto:xxxx@xxxx.com" target="_blank"
              ><i class="fa fa-envelope-o fa-fw mx-1 fa-lg"></i>xxxx@xxxx.com</a
            >
            <hr />
            <a
              class="btn btn-link"
              href="https://www.facebook.com/conciligoficial"
              target="_blank"
              ><i class="fa fa-facebook-square fa-fw mx-1 fa-lg"></i
            ></a>
            <a
              class="btn btn-link"
              href="https://www.instagram.com/concilig_oficial/"
              target="_blank"
              ><i class="fa fa-instagram fa-fw mx-1 fa-lg"></i
            ></a>
            <a
              class="btn btn-link"
              href="https://www.linkedin.com/company/concilig"
              target="_blank"
              ><i class="fa fa-linkedin fa-fw mx-1 fa-lg"></i
            ></a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <p class="mb-0 mt-2">@tricard</p>
          </div>
        </div>
      </div>
    </div>

   <!-- <div class="nav">
      <a class="nav-link wts bg-success" @click="openWhats()" target="_blank" style="cursor:pointer">
        <i class="fa fa-2x fa-whatsapp text-light"></i>
      </a>
    </div> -->
  </div>
    

</template>

<script>
import { mapActions,mapState } from "vuex";

export default {
    name: "Home",
    components: {
    },
    data: () => ({
       authlinks: [
            { icon: '', text: 'Início', route: '/dashboard' },
        ],
        hrefwhatsapp:'https://api.whatsapp.com/send?l=pt&phone=551132303765',
        links: [
            { icon: '', text: 'Quem Somos', route: 'somos'},
            { icon: '', text: 'Experimente', route: 'experimente'},
            { icon: '', text: 'Termos de uso', route: 'termos'},
            { icon: '', text: 'Política de privacidade', route: 'sobre'},
            { icon: '', text: 'Política de cookies', route: 'sobre'}
        ],
        largura: null
    }),
    methods: {
        ...mapActions('auth', ['ActionDoLogout','ActionMenuLinks','ActionGetUser','ActionGetWhatsText']),
        ...mapActions('meusBoletos', ['ActionSendAccessLogWithoutToken']),
        async openWhats () {
          if(this.user != null){
            var whats = await this.ActionGetWhatsText();
            this.hrefwhatsapp = this.hrefwhatsapp + '&text='+ whats[0];
          }else{
            // console.log('registrando log...');
             await this.ActionSendAccessLogWithoutToken({
              ValueId : 16
            });
            this.hrefwhatsapp = this.hrefwhatsapp + '&text=Olá, não encontrei meus dados podem me ajudar ?';
            
            // console.log('log registrado.');
          }           

           window.open(this.hrefwhatsapp, "_blank");
        }
    },
    async created() {
      this.largura = window.screen.width;
      // if(this.user != null){
      //   await this.ActionGetUser();
      //     this.user.Menus.forEach(menuId => {
      //         switch(parseInt(menuId)){
      //             case 2:
      //                 this.authlinks.push({ icon: '', text: 'Contratos Negociáveis', route: '/dashboard/minhas-dividas' });
      //                 break;
      //             case 1:
      //                 // this.authlinks.push({ icon: '', text: 'Boletos Ativos', route: '/dashboard/meus-boletos' });
      //                 break;
      //             case 3:
      //                 this.authlinks.push({ icon: '', text: 'Acordos', route: '/dashboard/meus-acordos' });
      //                 break;
      //         }
      //     });
      // }
          
    },
    computed:{ 
        ...mapState('auth', ['user']),
        isLoggedIn(){ 
            return this.$store.getters['auth/isLoggedIn']
        } 
    }
};
</script>
